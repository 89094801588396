import { memoize } from "lodash"
import { BASE_URL, memoizeResolver, SearchParams, getApiHeaders } from "./settings"

const URL_SYMBOL = 'Symbols'

const QuoteApi = {

  autocompleteSuggestion: memoize(function (word, limit = 7) {

    if (typeof word !== 'string' || word.length < 2)
      return null
    if (!Number.isInteger(limit) || limit < 1)
      limit = 1
    const originId = [1, 2, 3, 9, 3083, 13118, 15157, 15158, 15153, 17195, 15160, 57201, 687, 1058]
    const searchParams = SearchParams({ Palavra: word, Limite: +limit, originId })

    return fetch(`${BASE_URL}/${URL_SYMBOL}/Suggestion?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => response.json())
  }, memoizeResolver),

}

export default QuoteApi